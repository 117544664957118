const jquery = require('jquery');
window.$ = window.jQuery = jquery;

const typeahead = require('typeahead.js');

import Bloodhound from 'bloodhound-js';
import Granim from 'Granim';
import slick from 'slick-carousel';
import './cc.js';

import './index.scss';

const siteHandle = document.body.getAttribute('data-handle') || 'default';

const CANVAS_GRADDIENTS = {
  default: {
    image: require('./header.jpg'),
    gradients: [
      ['#22D4FD', '#B721FF'],
      ['#FD3381', '#555DCA'],
      ['#D31EFB', '#47BEEE'],
    ],
  },
  essendigitalisiert: {
    image: require('./2022_EWG_Startup_BG.jpg'),
    gradients: [
      ['#002A46', '#002A46'],
      ['#002A46', '#002A46'],
      ['#002A46', '#002A46'],
    ],
  },
};

$(function () {
  //console.log()
  $(
    '.content-wrapper .item h2, .content-wrapper .item img, .content-wrapper .item .text',
  ).on('click', function () {
    var url = $(this).closest('.item').attr('data-url');
    if (url.substring(0, 4) == 'http') {
      window.open(url);
    } else {
      document.location.href = url;
    }
  });
  $(document).delegate(
    'a[href*="http"],a[href*=".pdf"]',
    'click',
    function (e) {
      $(this).attr('target', '_blank');
    },
  );
  $(document).delegate('.subpage .beratung', 'click', function (e) {
    $(this).toggleClass('active');
  });
});

var granimInstance = new Granim({
  element: `.canvas-header`,
  name: 'basic-gradient',
  direction: 'left-right',
  opacity: [1, 1],
  isPausedWhenNotInView: true,
  image: {
    source: CANVAS_GRADDIENTS[siteHandle].image,
    position: ['center', 'center'],
    stretchMode: ['stretch', 'stretch'],
    blendingMode: 'hard-light',
  },
  states: {
    'default-state': CANVAS_GRADDIENTS[siteHandle],
  },
  transitionSpeed: 4000,
});

$(function () {
  $('.gallery').slick({
    dots: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '15px',
    variableWidth: true,
    // //initialSlide: 1,
    // pauseOnHover: false,
    // //fade: true,
    // //cssEase: 'linear',
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('.toggle').click(function (e) {
    e.preventDefault();

    var $this = $(this);

    if ($this.next().hasClass('show')) {
      $this.next().removeClass('show');
      $this.next().slideUp(350);
    } else {
      $this.parent().parent().find('.inner').removeClass('show');
      $this.parent().parent().find('.inner').slideUp(350);
      $this.next().toggleClass('show');
      $this.next().slideToggle(350);
    }
  });
});

function initNavigation() {
  $(window)
    .resize(function () {
      if (document.getElementById('jumbo') != null) {
        var jh = $('#jumbo').outerHeight();
        var ot = $('#jumbo').offset().top;
        $('#searchinnerwrapper').css({ top: jh + ot });
      }
    })
    .trigger('resize');

  var offset = $('#searchinnerwrapper').offset();
  //console.log(offset.top);

  // constructs the suggestion engine
  var myTags = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
    // datumTokenizer: function(d) {
    //     var test = Bloodhound.tokenizers.whitespace(d.value);
    //         $.each(test,function(k,v){
    //             i = 0;
    //             while( (i+1) < v.length ){
    //                 test.push(v.substr(i,v.length));
    //                 i++;
    //             }
    //         })
    //         return test;
    //     },
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    prefetch: {
      url: '/json',
      ttl: 100,
    },
  });

  function tagsWithDefaults(q, sync) {
    if (q === '') {
      // first five elements of array
      sync(myTags.all().slice(0, 7));
    } else {
      myTags.search(q, sync);
    }
  }

  $('#searchfield')
    .typeahead(
      {
        hint: true,
        highlight: false,
        minLength: 0,
      },
      {
        name: 'my-tags',
        limit: 7,
        display: 'title',
        source: tagsWithDefaults,
      },
    )
    .bind('typeahead:select', function (ev, suggestion) {
      //console.log('Selection: ' + suggestion);
      window.location = suggestion.url;
    })
    .bind('typeahead:close', function (ev, suggestion) {
      $('#searchwrapper').removeClass('active');
      $('body').focus();
      var jh = $('#jumbo').outerHeight();
      var ot = $('#jumbo').offset().top;
      $('#searchinnerwrapper').css({ top: jh + ot });
      $('.subpage #searchinnerwrapper').css({ top: '25px' });
    })
    .bind('typeahead:active', function (ev, suggestion) {
      $('#searchinnerwrapper').css({ top: offset.top - $(window).scrollTop() });
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        $('#searchinnerwrapper').css({ top: '25px' });
      }
    })
    .on('keypress', function (evt) {
      if (evt.which == 13 /* ENTER */) {
        const typeahead = $(this).data().ttTypeahead;
        const hintText = typeahead.input.$hint.val();
        const menu = typeahead.menu;
        const sel = menu.getActiveSelectable() || menu.getTopSelectable();
        if (menu.isOpen()) {
          menu.trigger('selectableClicked', sel);
          evt.preventDefault();
        }
      }
    });

  var searchwrapper = document.getElementById('searchwrapper');

  document.getElementById('searchfield').onfocus = function () {
    searchwrapper.className += ' active';
  };

  document.getElementById('searchclose').onclick = function () {
    searchwrapper.classList.remove('active');
  };
}

if (siteHandle != 'essendigitalisiert') initNavigation();

const hamburgerElement = document.querySelector('.hamburger');
const navCloseElement = document.querySelector('.navclose');
const wrapperElement = document.querySelector('.menu-wrapper');
hamburgerElement.addEventListener('click', () => {
  document.body.classList.add('navactive');
});
navCloseElement.addEventListener('click', () => {
  document.body.classList.remove('navactive');
});
wrapperElement.addEventListener('click', () => {
  document.body.classList.remove('navactive');
});
